<template>
    <div>
        <ChirpList
            headerText="Client Invoices"
            :data_source="rows"
            :column_meta="headers"
            :preFormattedData="true"
            :totalCount="totalCount"
            @rowSelected="handleRowClicked"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="useOverride = false"
        />
    </div>
</template>

<script>
    import ChirpList from '@/components/general/list/ChirpList';
    import { invoices } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';
    import { tryGetFilter } from '@/util/tryGetFilter';

    export default {
        name: 'ClientInvoices',
        components: { ChirpList },
        props: {
            client_id: {
                type: [String, Number],
                required: true
            },
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                headers: [
                    { field: 'dayt_create', headerText: 'Date', allowSorting: true },
                    { field: 'status', headerText: 'Status', allowSorting: true },
                    { field: 'amount_due', headerText: 'Amount Due', allowSorting: true },
                    { field: 'amount_paid', headerText: 'Amount Paid', allowSorting: true },
                    { field: 'amount_credited', headerText: 'Amount Credited', allowSorting: true },
                    { field: 'invoice_num', headerText: 'Invoice #', allowSorting: true },
                ],
                targetPage: 'ClientInvoice',
                totalCount: 0,
                useOverride: false,
                loading: true,
                loadingTable: false,
                criteriaProps: {}
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return 'clientInvoices';
            }
        },
        methods: {
            async loadData() {
                const res = await this.$api.post(invoices.getList(), {
                    clientId: this.client_id,
                    criteria: this.criteria
                });

                this.rows = res.data.rows.map((row) => ({
                    dayt_create: dayjs(row.dayt_create).format('MM/DD/YYYY'),
                    status: row.status,
                    amount_paid: this.$getCurrency(row.amount_paid),
                    amount_credited: this.$getCurrency(row.amount_credited),
                    amount_due: this.$getCurrency(row.amount_due),
                    invoice_num: row.id,
                }));

                this.totalCount = res.data.total_count;
            },
            async handleCriteriaModified(criteria) {
                this.criteriaProps = {
                    ...criteria
                };
                await this.loadData();
            },
            handleRowClicked(data) {
                this.$router.push({
                    name: 'Invoice',
                    params: { invoice_id: data.invoice_num }
                });
            }
        },
        async created() {
            if (this.overrideFilter) {
                this.useOverride = true;
                this.$store.commit('filters/applyOverride', {
                    stateKey: this.storeKey,
                    criteria: this.overrideFilter
                });
            }

            await this.loadData();
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.loadData();
                }
            }
        }
    };
</script>

<style scoped></style>
